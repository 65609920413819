import React from "react";
import {Button, Form, Label} from "semantic-ui-react";
import browserHistory from "../../helper/browserHistory";
import userStore from "../../stores/userStore";
import PropTypes from "prop-types";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {createText, updateText} from "../../helper/util";

@observer
class EditIconButtonComponent extends React.Component {

    @observable language = "de-DE";


    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            editValue: "",
            editLinkState: false,
            editLinkValue: ""
        }
        let ids = [this.props.textId, this.props.linkTextId];
        for (let i = 0; i < ids.length; i++) {
            if (typeof this.props.textStore[ids[i]] === "undefined") {
                fetchTextById(ids[i]).then(text => {
                    if (typeof text !== "undefined") {
                        this.setState(prevState => ({
                            ...prevState,
                            textStore: {...prevState.textStore, [ids[i]]: text[ids[i]]}
                        }));
                    }
                })
            }
        }
    }

    handleChange(type, event, data) {
        if (type === 0) {

         this.setState({...this.state, editValue: data.value})
        } else {
            this.setState({...this.state, editLinkValue: data.value})
        }
    }


    render() {
        const {textId, linkTextId, cssClass} = this.props;
        const textStore = this.state.textStore;
        if (!textId || !linkTextId) {
            console.log('Src is not acceptable');
            return null;
        }

        let displayType = userStore.userFromServer !== null ? "admin" : "user";
        let contentToShow;

        let textToEdit = {content: ""};
        if (typeof textStore[textId] !== "undefined") {
            if (typeof textStore[textId][this.language] !== "undefined") {
                textToEdit = textStore[textId][this.language];
            } else {
                textToEdit = {content: ""};
            }
        }

        let linkToEdit = {content: ""};
        if (typeof textStore[linkTextId] !== "undefined") {
            if (typeof textStore[linkTextId][this.language] !== "undefined") {
                linkToEdit = textStore[linkTextId][this.language];
            } else {
                linkToEdit = {content: ""};
            }
        }

        if (displayType === "admin") {
            let style = {
                "zIndex": 1,
                "position": "relative",
                "backgroundColor": "white",
                "overflow": "auto"
            };


            contentToShow = <div style={style}>
                {this.state.editState ?
                    <div>
                        <Form>
                            <p><b>Button Icon</b></p>
                            <Form.Input value={this.state.editValue} onChange={this.handleChange.bind(this, 0)}/>
                        </Form>
                        <br/>
                        <Button.Group>
                            <Button color="green" onClick={() => {
                                if (typeof textStore[textId] === "undefined" || typeof textStore[textId]["de-DE"] === "undefined") {
                                    createText(textId, {content: this.state.editValue}, "de-DE", this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(textId).then(text => {
                                                textStore[textId] = text[textId];
                                                this.setState({...this.state, editState: false, editValue: ""});

                                            })
                                        }
                                    });
                                } else {
                                    updateText(textId, {content: this.state.editValue}, "de-DE", this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(textId).then(text => {
                                                textStore[textId] = text[textId];
                                                this.setState({...this.state, editState: false, editValue: ""});
                                            })
                                        }
                                    });
                                }
                            }}>Speichern</Button>
                            <Button color="red" onClick={() => {
                                this.setState({...this.state, editState: false});
                            }}>Abbrechen</Button>
                        </Button.Group>
                    </div>
                    :
                    <div>
                        <p><b>Button Icon</b></p>
                        <p>{textToEdit.content}</p>
                        {textToEdit.isNamespace === this.props.namespace ? "" :
                            <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                                   color={"red"}>!</Label>}
                        <br/>
                        <Button color="orange" icon="edit outline" onClick={() => {
                            this.setState({...this.state, editState: true, editValue: textToEdit.content});
                        }}/>
                    </div>

                }
                {this.state.editLinkState ?
                    <div>
                        <Form>
                            <p><b>Button Link</b></p>
                            <Form.Input value={this.state.editLinkValue} onChange={this.handleChange.bind(this, 1)}/>
                        </Form>
                        <br/>
                        <Button.Group>
                            <Button color="green" onClick={() => {
                                if (typeof textStore[linkTextId] === "undefined" || typeof textStore[linkTextId]["de-DE"] === "undefined") {
                                    createText(linkTextId, {content: this.state.editLinkValue}, "de-DE", this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(linkTextId).then(text => {
                                                textStore[linkTextId] = text[linkTextId];
                                                this.setState({...this.state, editLinkState: false, editLinkValue: ""})
                                            })
                                        }
                                    });
                                } else {
                                    updateText(linkTextId, {content: this.state.editLinkValue}, "de-DE", this.props.namespace).then(isSuccess => {
                                        if (isSuccess) {
                                            fetchTextById(linkTextId).then(text => {
                                                textStore[linkTextId] = text[linkTextId];
                                                this.setState({...this.state, editLinkState: false, editLinkValue: ""})
                                            })
                                        }
                                    });
                                }
                            }}>Speichern</Button>
                            <Button color="red" onClick={() => {
                                this.setState({...this.state, editLinkState: false})
                            }}>Abbrechen</Button>
                        </Button.Group>
                    </div>
                    :
                    <div>
                        <p><b>Button Link</b></p>
                        <p>{linkToEdit.content}</p>
                        {linkToEdit.isNamespace === this.props.namespace ? "" :
                            <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                                   color={"red"}>!</Label>}
                        <br/>
                        <Button color="orange" icon="edit outline" onClick={() => {
                            this.setState({...this.state, editLinkState: true, editLinkValue: linkToEdit.content})
                        }}/>
                    </div>
                }
            </div>;
        } else {
            contentToShow = <Button icon={textToEdit.content} floated={"right"} className={cssClass}
                                    onClick={() => {
                                        if (linkToEdit.content.startsWith("http")) {
                                            window.open(linkToEdit.content, "_blank")
                                        } else {
                                            browserHistory.push(linkToEdit.content);
                                            this.setState({});
                                        }
                                    }}/>;

        }
        return (
            contentToShow
        )
    }
}

EditIconButtonComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    textId: PropTypes.string,
    linkTextId: PropTypes.string,
    cssClass: PropTypes.string,
};

EditIconButtonComponent.defaultProps = {
    textStore: {},
    namespace: null,
    textId: null,
    linkTextId: null,
    cssClass: ""
};

export default EditIconButtonComponent