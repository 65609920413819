import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import EditTextComponent from "../../components/moap-components/EditTextComponent";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import CardIconComponent from "../../components/card/CardIconComponent";

let scrollToComponent = function () {
};


if (typeof document !== "undefined") {
    scrollToComponent = require('react-scroll-to-component');
}


const PAGE_LINK = "Aktiv mitwirken";
const namespace = "engage"

@inject("stores")
@observer
class EngagePage extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true,
                infoModalOpen: false,
                open: false
            }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        let location = this.props.location;
        if (location !== undefined && location.hasOwnProperty("search")) {
            let search = location.search;
            if (search.startsWith("?") && search.length > 1) {
                let section = search.substr(1);
                //console.log(section);
                switch (section) {
                    case "0":
                        scrollToComponent(this.zero, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "1":
                        scrollToComponent(this.one, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "2":
                        scrollToComponent(this.two, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "3":
                        scrollToComponent(this.three, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "4":
                        scrollToComponent(this.four, {offset: 0, align: 'top', duration: 0});
                        break;
                    case "5":
                        scrollToComponent(this.five, {offset: 0, align: 'top', duration: 0});
                        break;
                    default:
                        break;
                }
            }
        }
    }

    render() {
        return (
            <Grid>
                <Modal
                    style={{"margin": "auto"}}
                    onClose={() => this.setState({...this.state, infoModalOpen: false})}
                    open={this.state.infoModalOpen}>
                    <Modal.Content
                        scrolling>
                        <p className={"text-block"}>

                            <EditTextComponent textStore={this.state.textStore} id={"donate-sub-description-111-id"}
                                               namespace={'engage'}/>
                        </p>
                        <Button circular fluid style={{"width": "60%"}} className={"call-to-action-button"}
                                onClick={() => this.setState({...this.state, infoModalOpen: false})}>
                            OK
                            <Icon name='checkmark'/>
                        </Button>
                    </Modal.Content>
                </Modal>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={namespace}
                    imageLinkId={`${namespace}-title-image-id`}
                    headerId={`${namespace}-title-header-id`}
                    subheaderId={`${namespace}-subtitle-header-id`}
                    buttonTextId={`${namespace}-button-text-id`}
                    buttonLinkId={`${namespace}-button-link-id`}/>
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section
                                className={"active-breadcrumb nav-item-red"}>MITWIRKEN</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={2} only={'computer'}/>
                <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Moneywhite.svg"}
                        headerId={`home-icon-card-4-header-id`}
                        subheaderId={`home-icon-card-4-subheader-id`}
                        color={"green"}
                        buttonTextId={`home-icon-card-4-button-text-id`}
                        buttonLinkId={`home-icon-card-4-button-link-id`}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={`home-icon-card-5-header-id`}
                        subheaderId={`home-icon-card-5-subheader-id`}
                        color={"light-blue"}
                        buttonTextId={`home-icon-card-5-button-text-id`}
                        buttonLinkId={`home-icon-card-5-button-link-id`}
                    />
                        <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Peoplewhite.svg"}
                        headerId={`home-icon-card-6-header-id`}
                        subheaderId={`home}-icon-card-6-subheader-id`}
                        color={"orange"}
                        buttonTextId={`home-icon-card-6-button-text-id`}
                        buttonLinkId={`home-icon-card-6-button-link-id`}
                    />
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column width={2} only={'computer'}/>
                   <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Handshakewhite.svg"}
                        headerId={`home-icon-card-7-header-id`}
                        subheaderId={`home-icon-card-7-subheader-id`}
                        color={"red"}
                        buttonTextId={`home-icon-card-7-button-text-id`}
                        buttonLinkId={`home-icon-card-7-button-link-id`}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Ideawhite.svg"}
                        headerId={`home-icon-card-8-header-id`}
                        subheaderId={`home-icon-card-8-subheader-id`}
                        color={"yellow"}
                        buttonTextId={`home-icon-card-8-button-text-id`}
                        buttonLinkId={`home-icon-card-8-button-link-id`}
                    />
                    <CardIconComponent
                        textStore={this.state.textStore}
                        namespace={'home'}
                        iconNameId={"Peoplewhite.svg"}
                        headerId={`home-icon-card-9-header-id`}
                        subheaderId={`home-icon-card-9-subheader-id`}
                        color={"green"}
                        buttonTextId={`home-icon-card-9-button-text-id`}
                        buttonLinkId={`home-icon-card-9-button-link-id`}
                    />
                    <Grid.Column width={2} only={'computer'}/>
                </Grid.Row>
            </Grid>
        );
    }
}



export default EngagePage