import React from "react";
import PropTypes from "prop-types";
import EditSimpleValueComponent from "../moap-components/EditSimpleValueComponent";
import EditIconButtonComponent from "../moap-components/EditIconButtonComponent";
import {observer} from "mobx-react";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

@observer
class CardIconComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            infoModalOpen: false
        }
    }

    render() {
        const {
            headerId,
            iconNameId,
            subheaderId,
            color,
            buttonTextId,
            buttonLinkId,
            infoTextId,
            bottomButtonLink,
            bottomButtonText
        } = this.props;
        if (!headerId || !subheaderId || !iconNameId || !buttonTextId || !buttonLinkId) {
            console.log('Src is not acceptable');
            return null;
        }

        let cssClass = "point-blue";
        if (color) {
            if (color === "orange") {
                cssClass = "point-orange"
            } else if (color === "light-blue") {
                cssClass = "point-light-blue"
            } else if (color === "yellow") {
                cssClass = "point-yellow"
            } else if (color === "green") {
                cssClass = "point-green"
            }
        }

        return (

            <Grid.Column computer={4} tablet={8} mobile={16} stretched className={"icon-card-container"}>
                <div className={cssClass + " half-out-center"}>
                    <Image
                        width={90}
                        height={90}
                        src={"https://www.buergerstiftung-kreis-rv.de/images/iconset/white/" + iconNameId}
                        alt={"Card Icon"}
                    />
                </div>
                <Card fluid className={"card-shadow"} style={{height: "100%"}}>
                    <Card.Content>
                        <Card.Header className={"icon-card-header"}>
                            <h3><EditSimpleValueComponent textStore={this.props.textStore} id={headerId}
                                                          namespace={this.props.namespace}/></h3>
                        </Card.Header>
                        <Card.Description>
                            <p className={"text-block"} style={{paddingBottom: "15px"}}><EditSimpleValueComponent
                                textStore={this.props.textStore}
                                id={subheaderId} namespace={this.props.namespace}/></p>
                        </Card.Description>
                        <EditIconButtonComponent textStore={this.props.textStore}
                                                 cssClass={"card-button card-button-position"} textId={buttonTextId}
                                                 linkTextId={buttonLinkId} namespace={this.props.namespace}/>
                        {infoTextId != null ?
                            <Button
                                className={"card-button card-button-position"}
                                icon={"question circle outline"}
                                onClick={() => this.setState({...this.state, infoModalOpen: true})}/>
                            :
                            null
                        }
                        {infoTextId != null ?
                            <Modal
                                style={{"margin": "auto"}}
                                onClose={() => this.setState({...this.state, infoModalOpen: false})}
                                open={this.state.infoModalOpen}>
                                <Modal.Content
                                    scrolling>
                                    <p className={"text-block"}>
                                        <EditHTMLComponent textStore={this.props.textStore} id={infoTextId}
                                                           namespace={this.props.namespace}/>
                                    </p>
                                    <Button circular fluid style={{width: "60%"}} className={"call-to-action-button"}
                                            onClick={() => this.setState({...this.state, infoModalOpen: false})}>
                                        OK <Icon name='checkmark'/>
                                    </Button>
                                </Modal.Content>
                            </Modal>
                            :
                            null
                        }
                    </Card.Content>
                </Card>
                {bottomButtonLink != null ?
                    <Grid.Column computer={4} tablet={8} mobile={16} textAlign={"center"}>

                        <Button circular fluid className={"call-to-action-button"}
                                onClick={() => {
                                    window.open(bottomButtonLink, "_blank")
                                }}>
                            {bottomButtonText.toUpperCase()}
                        </Button>
                    </Grid.Column>
                    :
                    null
                }
            </Grid.Column>
        )
    }
}

CardIconComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    headerId: PropTypes.string,
    subheaderId: PropTypes.string,
    iconNameId: PropTypes.string,
    color: PropTypes.string,
    buttonTextId: PropTypes.string,
    buttonLinkId: PropTypes.string,
    infoTextId: PropTypes.string,
    bottomButtonLink: PropTypes.string,
    bottomButtonText: PropTypes.string
};

CardIconComponent.defaultProps = {
    textStore: {},
    namespace: null,
    headerId: null,
    subheaderId: null,
    iconNameId: null,
    color: null,
    buttonTextId: null,
    buttonLinkId: null,
    infoTextId: null,
    bottomButtonLink: null,
    bottomButtonText: "bottomButtonText"
};

export default CardIconComponent